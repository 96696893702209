import {
  Avatar,
  Box,
  BoxProps,
  CloseButton,
  Drawer,
  DrawerContent,
  Flex,
  FlexProps,
  HStack,
  Icon,
  IconButton,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MenuIcon from "@mui/icons-material/Menu";
import React, { ReactNode, useContext, useState } from "react";
import { Link as ReactRouterLink, useLocation } from "react-router-dom";

import { UserContext } from "../../user_app/app";
import claribee_logo_512 from "./../../../../assets/logos/claribee_logo_512.png";

// This SidebarWithHeader was created referencing to the following Chakra template:
// https://chakra-templates.dev/navigation/sidebar
export interface LinkItemProps {
  name: string;
  icon: any;
  path: string;
}

export default function SidebarWithHeader({
  sidebarLinkItems,
  onLogout,
  children,
}: {
  sidebarLinkItems: Array<LinkItemProps>;
  onLogout: () => void;
  children: ReactNode;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Box minH="100vh">
      <SidebarContent
        sidebarLinkItems={sidebarLinkItems}
        onClose={() => onClose}
        display={{ base: "none", md: "block" }}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent
            sidebarLinkItems={sidebarLinkItems}
            onClose={onClose}
          />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav onOpen={onOpen} onLogout={onLogout} onClose={onClose} />
      <Box ml={{ base: 0, md: 60 }} p="4">
        {children}
      </Box>
    </Box>
  );
}

interface SidebarProps extends BoxProps {
  sidebarLinkItems: Array<LinkItemProps>;
  onClose: () => void;
}

const SidebarContent = ({
  sidebarLinkItems,
  onClose,
  ...rest
}: SidebarProps) => {
  const location = useLocation();
  const currentPathFromLinkItems = sidebarLinkItems.find((linkItem) =>
    location.pathname.includes(linkItem.path)
  );
  const [currentTab, setCurrentTab] = useState(currentPathFromLinkItems?.path);

  return (
    <Box
      transition="3s ease"
      borderRight="1px"
      borderRightColor="core.chocolate"
      w={{ base: "full", md: 60 }}
      pos="fixed"
      h="full"
      {...rest}
    >
      <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
        <Image
          maxHeight="55px"
          marginLeft="auto"
          marginRight="auto"
          src={claribee_logo_512}
          alt="sidebarLogo"
        />
        <CloseButton display={{ base: "flex", md: "none" }} onClick={onClose} />
      </Flex>
      <Box marginTop="2em" padding="0.5em">
        {sidebarLinkItems.map((linkItem) => (
          <NavItem
            key={linkItem.name}
            icon={linkItem.icon}
            path={linkItem.path}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            onClose={onClose}
          >
            {linkItem.name}
          </NavItem>
        ))}
      </Box>
    </Box>
  );
};

const NavItem = ({
  icon,
  path,
  currentTab,
  setCurrentTab,
  children,
  onClose,
}: {
  icon: any;
  path: string;
  currentTab?: string;
  setCurrentTab: React.Dispatch<React.SetStateAction<string | undefined>>;
  children: string | number;
  onClose: () => void;
}) => {
  return (
    <Link
      as={ReactRouterLink}
      to={path}
      _focus={{
        fontWeight: "bold",
        textDecoration: "underline 0.3em",
      }}
      onClick={() => {
        setCurrentTab(path);
        onClose();
      }}
    >
      <Flex
        align="center"
        p="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          bg: "core.apple",
          color: "gray.900",
        }}
        transition={"0.15s"}
        sx={{
          "&.active": {
            bg: "rgba(255,255,255,0.6)",
            color: "gray.900",
            fontWeight: "bold",
          },
        }}
        className={currentTab === path ? "active" : ""}
      >
        {icon && (
          <Icon
            mr="2"
            fontSize="24"
            _groupHover={{
              color: "gray.900",
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Link>
  );
};

interface MobileProps extends FlexProps {
  onOpen: () => void;
  onLogout: () => void;
  onClose: () => void;
}
const MobileNav = ({ onOpen, onLogout, onClose, ...rest }: MobileProps) => {
  const userProfile = useContext(UserContext);
  const fullName = `${userProfile?.firstName} ${userProfile?.lastName}`;

  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height="20"
      alignItems="center"
      borderBottomWidth="1px"
      borderBottomColor="core.chocolate"
      justifyContent={{ base: "space-between", md: "flex-end" }}
      {...rest}
    >
      <IconButton
        display={{ base: "flex", md: "none" }}
        onClick={onOpen}
        variant="outline"
        aria-label="open menu"
        icon={<MenuIcon />}
      />

      <HStack spacing={{ base: "0", md: "6" }}>
        <Flex alignItems={"center"}>
          <Menu>
            <MenuButton
              py={2}
              transition="all 0.3s"
              _focus={{
                border: "solid 2px #4f5a51",
              }}
            >
              <HStack>
                <Avatar size={"sm"} name={fullName} />
                <VStack
                  display={{ base: "none", md: "flex" }}
                  alignItems="flex-start"
                  spacing="1px"
                  ml="2"
                >
                  <Text fontSize="sm">{userProfile?.firstName}</Text>
                </VStack>
                <Box display={{ base: "none", md: "flex" }}>
                  <ExpandMoreIcon />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList borderColor="core.apple">
              {/* // TODO: Re-comment back in post-BETA
              <MenuItem>Profile Settings</MenuItem> */}
              <MenuItem data-umami-event="log-out-btn" onClick={onLogout}>
                Log out
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
    </Flex>
  );
};
