import React from "react";
import { Outlet } from "react-router";
import SidebarWithHeader, {
  LinkItemProps,
} from "../library/ui_custom/side_bar_with_header";

function Layout({
  linkItems,
  onLogout,
}: {
  linkItems: Array<LinkItemProps>;
  onLogout: () => void;
}) {
  return (
    <SidebarWithHeader sidebarLinkItems={linkItems} onLogout={onLogout}>
      <Outlet />
    </SidebarWithHeader>
  );
}
export default Layout;
