import { Link, chakra } from "@chakra-ui/react";
import React from "react";

const StyledFooter = chakra("footer", {
  baseStyle: { marginTop: "5em", padding: "1em", textAlign: "center" },
});

const StyledLink = chakra(Link, {
  baseStyle: { textDecoration: "underline", margin: "1em" },
});

export default function GlobalFooter() {
  return (
    <StyledFooter>
      <StyledLink
        href="https://drive.google.com/file/d/13mXzw5n0TySCFI4fIiJ4myPFDck8oOfi/view?usp=sharing"
        isExternal
        data-umami-event="footer-privacy-policy"
      >
        Privacy Policy
      </StyledLink>
      <StyledLink
        href="https://drive.google.com/file/d/12jHwCWwPzxG1HLbmv4RpTLNzhWvdCshP/view?usp=sharing"
        isExternal
        data-umami-event="footer-terms-and-conditions"
      >
        Terms & Conditions
      </StyledLink>
    </StyledFooter>
  );
}
